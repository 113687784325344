import React from 'react';

const UploadForm16 = ({ nextStep, handleFormData, values, prevStep }) => {
  return (
    <>
      <section
        id='feature-service'
        className='feature-service mt-5 pt-50'
      ></section>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='card text-center bnp'>
              <div className='card-body'>
                <p className='card-text'>
                  <strong>Upload Form 16 </strong>(or you can skip if do not
                  have one right now)
                </p>
                <p>
                  Income Tax Return Filing is easy with Tax2win and the smartest
                  way to online file your taxes return is to just upload your
                  form 16 and get your ITR prepared automatically.
                </p>
              </div>
              <div>
                <img src='/imgs/pdf.png' alt='files' />
              </div>
              <div className='mt-3'>
                <input
                  type='file'
                  id='img'
                  name='deduction_form'
                  accept='pdf'
                  value={values.deduction_form}
                  onChange={handleFormData}
                />
              </div>
              <div className='col-md-12 hra-btn text-right'>
                <button
                  className='btn btn-sm  form-prev-btn btn-primary'
                  onClick={prevStep}
                >
                  <i className='bi bi-arrow-left'></i>Back
                </button>
                <button
                  className='btn btn-md form-next-btn btn-success m-2'
                  onClick={nextStep}
                >
                  Next <i className='bi bi-arrow-right'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadForm16;
