import React from 'react';

const BasicDetails = ({
  nextStep,
  handleFormData,
  values,
  prevStep,
  setFormData,
  handleSubmit,
}) => {
  const genderChanged = () => {
    setFormData((prev) => {
      return { ...prev, gender_chk: !prev['gender_chk'] };
    });
  };
  return (
    <>
      <section
        id='feature-service'
        className='feature-service mt-5 pt-50'
      ></section>
      <section>
        <div className='container'>
          <div className='row text-center bnp'>
            <h2 className='card-text'>
              Some <strong>basic details </strong>about you!
            </h2>
            <p>We kept it secret</p>
          </div>
          <div className='row text-center bnp'>
            <div className='card  '>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-4'>
                    <label htmlFor='f_name'>
                      First Name <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='exampleFormControlInput1'
                      name='f_name'
                      placeholder='Enter First Name'
                      autoComplete='off'
                      value={values.f_name}
                      onChange={handleFormData}
                    />
                  </div>
                  <div className='col-md-4'>
                    <label htmlFor='m_name'>Middle Name</label>
                    <input
                      type='text'
                      className='form-control'
                      id='exampleFormControlInput1'
                      name='m_name'
                      placeholder='Enter Middle Name'
                      autoComplete='off'
                      value={values.m_name}
                      onChange={handleFormData}
                    />
                  </div>
                  <div className='col-md-4'>
                    <label htmlFor='l_name'>Last Name</label>
                    <input
                      type='text'
                      className='form-control'
                      id='exampleFormControlInput1'
                      name='l_name'
                      placeholder='Enter Last Name'
                      autoComplete='off'
                      value={values.l_name}
                      onChange={handleFormData}
                    />
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-3'>
                      <label htmlFor='father_name'>
                        Father Name <span className='text-danger'>*</span>
                      </label>

                      <input
                        type='text'
                        className='form-control'
                        id='exampleFormControlInput1'
                        name='father_name'
                        placeholder='Enter Father Name'
                        autoComplete='off'
                        value={values.father_name}
                        onChange={handleFormData}
                      />
                    </div>
                    <div className='col-md-3'>
                      <label htmlFor='dob'>
                        Date Of Birth<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='date'
                        className='form-control'
                        id='exampleFormControlInput1'
                        name='dob'
                        autoComplete='off'
                        value={values.dob}
                        onChange={handleFormData}
                      />
                    </div>
                    <div className='col-md-3'>
                      <label htmlFor='phone'>
                        Mobile Number<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='number'
                        className='form-control'
                        id='exampleFormControlInput1'
                        name='phone'
                        placeholder='99XXXXXXXX'
                        autoComplete='off'
                        value={values.phone}
                        onChange={handleFormData}
                      />
                    </div>
                    <div className='col-md-3'>
                      <label htmlFor='email'>
                        Email<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='email'
                        className='form-control'
                        id='exampleFormControlInput1'
                        name='email'
                        placeholder='abc@gamil.com'
                        autoComplete='off'
                        value={values.email}
                        onChange={handleFormData}
                      />
                    </div>
                  </div>
                  <div className='row text-center mt-3'>
                    <div>
                      <span
                        className={`${
                          values.gender_chk ? 'text-muted' : 'text-dark'
                        }`}
                      >
                        Male
                      </span>
                      <label className='switch'>
                        <input
                          type='checkbox'
                          name='gender_chk'
                          value='1'
                          onChange={genderChanged}
                        />
                        <span className='slider'></span>
                      </label>
                      <span
                        className={`${
                          values.gender_chk ? 'text-dark' : 'text-muted'
                        }`}
                      >
                        Female
                      </span>
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col bg-success'>
                      <p className='text-white mt-3'>
                        <strong>Note#:</strong> Please enter above mentioned
                        details as mentioned on your PAN card
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 hra-btn text-right'>
                <button
                  className='btn btn-sm  form-prev-btn btn-primary'
                  onClick={prevStep}
                >
                  <i className='bi bi-arrow-left'></i>Back
                </button>
                <button
                  className='btn btn-md form-next-btn btn-success m-2'
                  onClick={handleSubmit}
                >
                  Submit <i className='bi bi-arrow-right'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BasicDetails;
