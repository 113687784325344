import React from 'react';

const FinancialDetails = ({ nextStep, handleFormData, values, prevStep }) => {
  const FinancialYear = [
    {
      value: '0',
      code: `Select Financial Year`,
    },
    {
      value: '1',
      code: `2020-2021`,
    },
    {
      value: '2',
      code: `2021-2022`,
    },
    {
      value: '3',
      code: `2022-2023`,
    },
  ];
  return (
    <>
      <section
        id='feature-service'
        className='feature-service mt-5 pt-50'
      ></section>
      <section>
        <div className='container'>
          <div className='row text-center bnp'>
            <h2 className='card-text'>
              Enter Your<strong>PAN Details</strong>
            </h2>
            <p>Let us do the paperwork.</p>
          </div>
          <div className='row'>
            <div className='card  '>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-6'>
                    <p>Financial Year</p>
                    <select
                      name='financial_year'
                      id='financial_year'
                      className='form-control'
                      autoComplete='off'
                      value={values.financial_year}
                      onChange={handleFormData}
                    >
                      {FinancialYear.map((year, index) => (
                        <option value={year.value} key={index}>
                          {year.code}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-md-6'>
                    <p>PAN number</p>
                    <input
                      type='text'
                      className='form-control'
                      name='pan'
                      id='exampleFormControlInput1'
                      placeholder='ABCTY1234D'
                      value={values.pan}
                      onChange={handleFormData}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-12 hra-btn text-right'>
                <button
                  className='btn btn-sm  form-prev-btn btn-primary'
                  onClick={prevStep}
                >
                  <i className='bi bi-arrow-left'></i>Back
                </button>
                <button
                  className='btn btn-md form-next-btn btn-success m-2'
                  onClick={nextStep}
                >
                  Next <i className='bi bi-arrow-right'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinancialDetails;
