import { Link } from 'gatsby';
import React from 'react';
import Counter from '../../components/Counter';
import FAQAccord from '../../components/GFlush';
import Testimonial from '../../components/Testimonial';

const SourceofIncome = ({ nextStep, handleFormData, values, setFormData }) => {
  const salaryChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        salarY_pen: !prev['salarY_pen'],
        sal_peN: !prev['sal_peN'],
      };
    });
  };

  const businessChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        bus_proY: !prev['bus_proY'],
        bus_pro: !prev['bus_pro'],
      };
    });
  };

  const propertyChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        propertY_loan: !prev['propertY_loan'],
        proper_loan: !prev['proper_loan'],
      };
    });
  };

  const gainChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        gainsY: !prev['gainsY'],
        gains: !prev['gains'],
      };
    });
  };

  const otherIncomeChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        other_incomeY: !prev['other_incomeY'],
        other_income: !prev['other_income'],
      };
    });
  };

  const foreignIncomeChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        foreign_incomeY: !prev['foreign_incomeY'],
        foreign_income: !prev['foreign_income'],
      };
    });
  };

  /* FaQ Data */
  var PvtLtdFAQ = [
    {
      header: 'What is DSC?',
      body: [
        {
          content: `You can sign electronic documents using the DSC, which is simply a
            Digital Signature Certificate issued by certifying authority (TCS
            and n-Code). Since a DSC is required for every document when forming
            a private limited company in India.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'Can a private company operate more than one business?',
      body: [
        {
          content: `Yes, a Private Company may own more than one firm, but this must be
            stated in the MoA and approved by the registration.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can I register my company with a physical address at my home?',
      body: [
        {
          content: `With the proper documentation, you can have a private company's
            registered office at both a commercial and a residential location. A
            company's registered office is where it receives all official
            correspondence, such as from the MCA or other relevant agencies, and
            where the location is also listed on the site.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header:
        'Can a private limited company issue stock or equity to the public?',
      body: [
        {
          content: `A private limited company is not permitted to flog equity or shares
            to the public.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header:
        'What must a private company do each year in terms of compliance?',
      body: [
        {
          content: `The private limited corporation is required to hold one Annual General
            Meeting (AGM) and about four board meetings per fiscal year, or once
            every four months. Additionally, an independent auditor must
            frequently audit the company's financial statements and accounts. It
            must then submit forms AOC-4 and MGT-7 for annual compliance within
            the specified time frame.`,
          icon: false,
        },
      ],
    },
    {
      // 5
      header: 'What is Private Limited Company',
      body: [
        {
          content: `A Private Limited Company is a small company that is handled by a group of people privately. 
            The liability of the members of a Private Limited Company is limited to the number of shares respectively 
            held by them. Shares of Private Limited Company cannot be publicly traded.`,
          icon: false,
        },
      ],
    },

    {
      // 6
      header: 'Is an audit mandatory for a startup Private Limited Company?',
      body: [
        {
          content: `Be it a Startup or an established Private Limited company, It is mandatory compliance to have the 
            company’s account audited.
              Even the appointment of an auditor within 30 days of the incorporation is mandatory compliance for a 
              Private limited company.`,
          icon: false,
        },
      ],
    },
    {
      // 7
      header:
        'When should I file the Annual returns of my Private Limited Company?',
      body: [
        {
          content: `Private Limited Companies are required to file their Annual Accounts and Returns disclosing details 
            of its shareholders, directors, etc to the Registrar of Companies. Such compliances are required to be made 
            once a year.`,
          icon: false,
        },
      ],
    },
    {
      // 8
      header:
        'What is the minimum turnover and capital required for Private Limited Company in India?',
      body: [
        {
          content: `There exists no bar on turnover or capital in a private Limited Company, unlike One person Company.`,
          icon: false,
        },
      ],
    },
    {
      // 9
      header:
        'Is registration necessary for Private Limited Companies in India?',
      body: [
        {
          content: `Yes, Registration for a Private Limited Company is necessary as, without registration, there can be no existence of a company.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <>
      <section id='feature-service' className='feature-service mt-5 pt-50'>
        <div className='container'>
          <div className='section-header'>
            <h2>File Your Income Tax Return For FREE*</h2>
            <div className='bar'></div>

            {/* <p>
              Platform usage charges for income tax e filing - Rs. 299/- ( +
              Taxes ) will be applicable, if Total Income {'>'} Rs.2,50,000
            </p> */}
          </div>
          <p className='text-center mb-5'>
            TODAYFILINGS has made Online Income Tax Filing super easy! Take the
            first step towards income tax efiling Select all the sources of your
            income (to accurately e-file) and click on Continue to experience
            TODAYFILINGS's customized & the easiest itr efiling process.
          </p>
          <div className='row mt-5'>
            <div
              className='col-md-9 d-flex aos-init aos-animate'
              data-aos='fade-right'
              data-aos-offset='400'
            >
              <div className='row  gy-4'>
                <div className='col-md-12'>
                  <p className='text-center'>
                    The main benefits of forming a private limited company in
                    India over other entity forms are as follows.
                  </p>
                  <div className='feature-box d-flex align-items-center mb-2'>
                    <div className='col-sm-8 d-flex'>
                      <h6>Q1. Income from Salary/Pension ?</h6>
                    </div>
                    <div className='col-sm-4 d-flex'>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='salarY_pen'
                          id='salarY_pen'
                          checked={values.salarY_pen ? 'checked' : ''}
                          onChange={salaryChanged}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='salarY_pen'
                        >
                          <h5>Yes</h5>
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='incomeN_radio'
                          id='incomeN_radio'
                          checked={values.sal_peN ? 'checked' : ''}
                          onChange={salaryChanged}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='incomeN_radio'
                        >
                          <h5>No</h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='feature-box d-flex align-items-center mb-2'>
                    <div className='col-sm-8 d-flex'>
                      <h6>Q2. Income from Business/Profession ?</h6>
                    </div>
                    <div className='col-sm-4 d-flex'>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='bus_proY'
                          id='bus_proY'
                          checked={values.bus_proY ? 'checked' : ''}
                          onChange={businessChanged}
                        />
                        <label className='form-check-label' htmlFor='bus_proY'>
                          <h5>Yes</h5>
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='bus_pro'
                          id='bus_pro'
                          checked={values.bus_pro ? 'checked' : ''}
                          onChange={businessChanged}
                        />
                        <label className='form-check-label' htmlFor='bus_pro'>
                          <h5>No</h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='feature-box d-flex align-items-center mb-2'>
                    <div className='col-sm-8 d-flex'>
                      <h6>Q3. Income from House Property or Home Loan ?</h6>
                    </div>
                    <div className='col-sm-4 d-flex'>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='propertY_loan'
                          id='propertY_loan'
                          checked={values.propertY_loan ? 'checked' : ''}
                          onChange={propertyChanged}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='propertY_loan'
                        >
                          <h5>Yes</h5>
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='proper_loan'
                          id='proper_loan'
                          checked={values.proper_loan ? 'checked' : ''}
                          onChange={propertyChanged}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='proper_loan'
                        >
                          <h5>No</h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='feature-box d-flex align-items-center mb-2'>
                    <div className='col-sm-8 d-flex'>
                      <h6>Q4. Income from Capital Gains ?</h6>
                    </div>
                    <div className='col-sm-4 d-flex'>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='gainsY'
                          id='gainsY'
                          checked={values.gainsY ? 'checked' : ''}
                          onChange={gainChanged}
                        />
                        <label className='form-check-label' htmlFor='gainsY'>
                          <h5>Yes</h5>
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='gains'
                          id='gains'
                          checked={values.gains ? 'checked' : ''}
                          onChange={gainChanged}
                        />
                        <label className='form-check-label' htmlFor='gains'>
                          <h5>No</h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='feature-box d-flex align-items-center mb-2'>
                    <div className='col-sm-8 d-flex'>
                      <h6>Q5. Income from Other Sources ?</h6>
                    </div>
                    <div className='col-sm-4 d-flex'>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='other_incomeY'
                          id='other_incomeY'
                          checked={values.other_incomeY ? 'checked' : ''}
                          onChange={otherIncomeChanged}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='other_incomeY'
                        >
                          <h5>Yes</h5>
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='other_income'
                          id='other_income'
                          checked={values.other_income ? 'checked' : ''}
                          onChange={otherIncomeChanged}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='other_income'
                        >
                          <h5>No</h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='feature-box d-flex align-items-center mb-2'>
                    <div className='col-sm-8 d-flex'>
                      <h6>Q6. Income from Foreign Source ?</h6>
                    </div>
                    <div className='col-sm-4 d-flex'>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='foreign_incomeY'
                          id='foreign_incomeY'
                          checked={values.foreign_incomeY ? 'checked' : ''}
                          onChange={foreignIncomeChanged}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='foreign_incomeY'
                        >
                          <h5>Yes</h5>
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='foreign_income'
                          id='foreign_income'
                          checked={values.foreign_income ? 'checked' : ''}
                          onChange={foreignIncomeChanged}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='foreign_income'
                        >
                          <h5>No</h5>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12 hra-btn float-end'>
                    <button
                      className='btn btn-md form-next-btn btn-success m-2'
                      onClick={nextStep}
                    >
                      Next <i className='bi bi-arrow-right'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-2"></div> */}
            <div
              className='col-md-3 feature-service-img aos-init aos-animate card pad'
              data-aos='fade-left'
              data-aos-offset='400'
            >
              <div
                className='col-lg-12 col-md-12 aos-init aos-animate'
                data-aos='fade-up'
                data-aos-offset='200'
              >
                <div className=''>
                  <div className='row'>
                    <div className='col-md-3'>
                      <div id='child'>
                        <img
                          src='/imgs/icons/startup.png'
                          alt='TODAYFILINGS - Launch and Run a Startup service'
                        />
                      </div>
                    </div>

                    <div className='col-md-9'>
                      <div id='parent'>
                        <h6>Looking for expert CA For Your Tax Filing ?</h6>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4'>
                    <p className='desc'>
                      Get your personal eCA to do tax filing for you. it is
                      personal, convenient & fast with maximum refund calculated
                      for you.
                    </p>
                    <div className='row'>
                      <div className='d-flex footer-links'>
                        {/* bip css in index.css */}
                        <ul className='bip'>
                          <li>
                            <p>
                              <i className='bi bi-check-circle-fill pt-1'></i>
                              Save time and money
                            </p>
                          </li>
                          <li>
                            <p>
                              <i className='bi bi-check-circle-fill pt-1'></i>
                              Trusted by 1M+ Customers
                            </p>
                          </li>
                          <li>
                            <p>
                              <i className='bi bi-check-circle-fill pt-1'></i>
                              4.8 Service Rating
                            </p>
                          </li>
                          <li>
                            <p>
                              <i className='bi bi-check-circle-fill pt-1'></i>
                              Reliable & Secure ITR Filing
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='row eca text-center'>
                      <div className='col-md-12'>
                        <Link
                          className='form-next-btn align-self-start'
                          to='tel:9876543210'
                        >
                          Book eCA Now
                        </Link>

                        <p className='eca-text'>
                          Lowest Tax Filing Fees in India
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Counter />
      <FAQAccord items={PvtLtdFAQ} />
      <Testimonial className='bin' />
    </>
  );
};

export default SourceofIncome;
