import React, { useState } from 'react';
import BasicDetails from './BasicDetails';
import Disclaimer from './Disclaimer';
import FinancialDetails from './FinancialDetails';
import SourceofIncome from './SourceofIncome';
import UploadForm16 from './UploadForm16';
import axios from 'axios';

export default function ItrFiling() {
  const [currentStep, setCurrentStep] = useState(1);
  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  //state for form data
  const [formData, setFormData] = useState({
    salarY_pen: false,
    sal_peN: true,
    bus_proY: false,
    bus_pro: true,
    propertY_loan: false,
    proper_loan: true,
    gainsY: false,
    gains: true,
    other_incomeY: false,
    other_income: true,
    foreign_incomeY: false,
    foreign_income: true,
    gender_chk: true,
    deduction_form: '',
    financial_year: '',
    pan: '',
    f_name: '',
    m_name: '',
    l_name: '',
    father_name: '',
    dob: '',
    phone: '',
    email: '',
  });
  // console.log(formData);
  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (e) => {
    // input value from the form
    const { name, value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object

    setFormData({ ...formData, [name]: value });
  };
  const baseURL = 'https://app.todayfilings.com/api/itr-filing-mail';

  // const baseURL = 'http://127.0.0.1:8000/api/itr-filing-mail';
  const handleSubmit = (e) => {
    e.preventDefault();
    var data = {
      values: formData,
    };

    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:8000',
        'Content-Type': 'application/json',
      },
    };
    axios
      .post(baseURL, data, axiosConfig)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          nextStep();

          // alert('asdsad');
          // calc();
          // newRegCalc();
        } else {
          alert('no');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderSwitch = (param) => {
    switch (param) {
      // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 1:
        return (
          <SourceofIncome
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={formData}
            setFormData={setFormData}
          />
        );
      // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 2:
        return (
          <UploadForm16
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      // case 3 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 3:
        return (
          <FinancialDetails
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      // case 4 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 4:
        return (
          <BasicDetails
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
          />
        );
      case 5:
        return <Disclaimer prevStep={prevStep} />;
      // default case to show nothing
      default:
        return <div></div>;
    }
  };
  return (
    <section id='it_filing'>
      <form id='itrForm'>
        <section className=''></section>
        <div className='col-md-12 mt-5'>{renderSwitch(currentStep)}</div>
      </form>
    </section>
  );
}
