import { Link } from 'gatsby';
import React from 'react';

const Disclaimer = () => {
  return (
    <>
      <section
        id='feature-service'
        className='feature-service mt-5 pt-50'
      ></section>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='card  '>
              <div className='card-body'>
                <div className='row text-center bnp'>
                  <h2 className='card-text'>
                    <strong>Disclaimer!</strong>
                  </h2>
                  {/* <p>Let us do the paperwork.</p> */}

                  <div className='mt-3'>
                    <Link href='/' className='btn btn-primary'>
                      My Dashboard
                    </Link>
                  </div>
                  <div className='bottom'></div>
                  <div className='mt-5'>
                    <p>
                      In
                      case ofany assistance, please reach out to us at: <a href = 'tel:+91 9585055661' > +91 95850 55661 </a>  or email us at<a href='mailto:support@todayfilings.com'>support@todayfilings.com</a >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mt-3 text-right">
                <div className="container">
                  <button type="button" className="btn btn-primary btn-md bnp">
                    BACK
                  </button>
                  <button type="button" className="btn btn-secondary btn-md bnp">
                    CONTINUE
                  </button>
                </div>
              </div> */}
        </div>
      </section>
    </>
  );
};

export default Disclaimer;
