import React from 'react';
import ItrFiling from '../../components/ItrFiling/ItrFiling';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';

export default function Itr() {
  return (
    <>
      <Seo
        title='Online ITR Filing'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <ItrFiling />
      </Layout>
    </>
  );
}
